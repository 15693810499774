'use-strict';
import axios from 'axios';
// import { apiUrl } from '../utils/app.config';
import { apiUrl, routeConfig } from '../utils/constant';

export const fetchRTCToken = async (channel) => {
    try {
        const res = await axios.post(`${apiUrl}/${routeConfig.rtc}`, {
            channel: channel
        });
        return res.data.data;
    } catch (e) {
        console.log('Error from fetchRTCToken -> ', e);
    }
}

export const fetchAllPortals = async (channel) => {
    try {
        const res = await axios.get(`${apiUrl}/${routeConfig.getAllPortals}`);
        return res.data.data;
    } catch (e) {
        console.log('Error from fetchRTCToken -> ', e);
        return [];
    }
}