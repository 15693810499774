import React from 'react';
import { AppInfoProps } from "../interfaces/AppInfoProps";
// import { siteUrl } from '../utils/app.config';
import { siteUrl } from '../utils/constant';
import Logo from '../assets/img/logo.png';

const AppInfo: React.FC<AppInfoProps> = (props: AppInfoProps) => {
    const { client, showLogo } = props;

    return (
        <>
            <div className="content">
                {client ? (
                    <>
                        <h1 className="title heading_medium_bold">{client.title}</h1>
                        <h4 className="description label_14_light">{client.description}</h4>
                    </>
                ) : <></>
                }
            </div>
            {showLogo &&
                <a href={siteUrl}>
                    <img alt="Megavrse Logo" src={Logo} style={{ width: 218, position: 'absolute', top: 50, left: 50 }} />
                </a>
            }
        </>
    )
}

export default AppInfo;