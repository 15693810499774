'use-strict';

export const routeConfig = {
    getAllPortals: 'portal/getAll',
    rtc: 'rtctoken'
}

export const appCode = 'mega-office';
export const agoraAppId = '728e8447089c434d861a809607e74a0c';
// export const apiUrl = 'http://localhost:3000/api';
export const apiUrl = 'https://apistaging.depay.io/api'
export const siteUrl = 'https://megavrse.xyz';
