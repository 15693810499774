import React from 'react';
import AppInfo from '../components/AppInfo';

const FallbackPage = () => {
    return (
        <div className="landing-container" style={{ backgroundImage: `url('./images/bg.png')` }}>
            <div className="overlay"></div>
            <div className="landing-inner-container">
                <AppInfo showLogo={true} />
                <h3>Loading...</h3>
            </div>
        </div>
    )
}

export default FallbackPage;