export class ClientJson {
    environmentId?: string;
    launchType?: string;
    projectId?: string;
    modelId?: string;
    version?: string;
    endpoint?: string;
    usePointerLock?: boolean;
    pointerLockRelease?: boolean;
    useNativeTouchEvents?: boolean;
    resolution?: string;
    title?: string;
    description?: string;
    appCode?: string;
    isVoiceEnabled?: boolean;
}